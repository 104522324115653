/* eslint-disable max-lines-per-function */
import logger from "../logger";

// eslint-disable-next-line import/prefer-default-export
export const track = (event, rest, options) => {
  const additionalParams = {};
  const orderNumberContainer = document.querySelector(".js-order-number-container");

  if (["checkout_started", "checkout_confirmed"].includes(event)) {
    const variantDetails = orderNumberContainer
      ? orderNumberContainer.dataset.mxVariantDetails
      : "";
    additionalParams.variant_details = variantDetails;
  }

  if (["checkout_started", "checkout_confirmed", "saved_carts_default_changed"].includes(event)) {
    if (orderNumberContainer.dataset.subOrdersCount > 0) {
      additionalParams.consolidated_cart = true;
      additionalParams.number_of_child_carts = orderNumberContainer.dataset.subOrdersCount;
    }
  }

  if (window.mixpanel) {
    const timedEvents = {
      checkout_started: "checkout_page_rendered"
    };
    const properties = { ...rest, ...additionalParams };

    if (Object.keys(timedEvents).includes(event)) {
      window.mixpanel.time_event(timedEvents[event]);
    }
    window.mixpanel.track(event, properties, options);
  } else {
    logger.log(`Track ${event}: ${JSON.stringify(rest)} ${JSON.stringify(additionalParams)}`);
  }
};

export const registerMixpanel = props => {
  window.mixpanel?.register(props);
};
