import { tryParse } from "common/utils/json";
import { track } from "./tracker";

// eslint-disable-next-line import/prefer-default-export
export const initMixpanelEvents = (
  containerSelector = "body",
  elementSelector = "[data-mixpanel-event][data-mixpanel-listen]"
) => {
  document.querySelectorAll(`${containerSelector} ${elementSelector}`).forEach($el => {
    const { mixpanelLoaded, mixpanelListen, mixpanelEvent, mixpanelData } = $el.dataset;
    if (mixpanelLoaded === "true") return;
    $el.dataset.mixpanelLoaded = true;

    const eventTypes = mixpanelListen.split("|");
    const trackingNames = mixpanelEvent.split("|");
    const trackingData = mixpanelData?.split("|") || [];

    eventTypes.forEach((type, index) => {
      const name = trackingNames[index] || trackingNames[0];
      const datum = trackingData[index] || trackingData[0];

      $el.addEventListener(type, () => {
        track(name, datum && tryParse(datum));
      });
    });
  });
};
