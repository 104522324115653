export const timings = {
  // Document loaded
  tooltip: 0,
  // Window load
  ga: 0,
  klarnaBanner: 0,
  trustedStores: 500,
  productGrid: 1250,
  quantityForm: 750,
  zenDesk: 1000,
  ppCreditBanner: 1500
};

const idleCallback = (callback, delay = 0) => {
  setTimeout(() => {
    if (typeof window.requestIdleCallback === "function") {
      window.requestIdleCallback(callback);
    }
  }, delay);
};

export default idleCallback;
