// eslint-disable-next-line import/no-unresolved
import * as jiffyAnalytics from "jiffyAnalyticsOriginal";

export const {
  sendDataLayer,
  sendDataLayerOnLinkClickEvent,
  track,
  registerMixpanel,
  initMixpanelEvents
} = jiffyAnalytics;
